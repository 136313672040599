import React, { useMemo, ChangeEvent } from 'react'
import { Link as ScrollLink, scroller } from 'react-scroll'

import Card from '../../components/Card'
import { useHeaderContext } from '../../context'

import styles from '../../components/Article/scss/article.module.scss'

interface Props {
  toc: TocElement[]
}

export const TOC: React.FC<Props> = ({ toc }) => {
  const { isVisible } = useHeaderContext()
  const stickyClass = useMemo(() => (
    `${styles.sticky} ${isVisible ? styles.stickyWithNav : ''}`
  ), [isVisible])

  return (
    <div className={styles.sideCard}>
      <Card className={`${stickyClass} ${styles.sideCardInner}`} extraShadow>
        <ul className='grid-y grid-margin-y'>
          <li className='cell'>Content</li>
          {toc.map(({ text, to }) => (
            <li key={to} className='cell pink'>
              {to ? (
                <ScrollLink to={to} smooth={true} className={styles.tocLink}>
                  {text}
                </ScrollLink>
              ) : text}
            </li>
          ))}
        </ul>
      </Card>
    </div>
  )
}

export const TOCDropdown: React.FC<Props> = ({ toc }) => {
  const onChange = React.useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    if (event.target.value) {
      scroller.scrollTo(event.target.value, { smooth: true })
    }
  }, [])
  return (
    <div className={styles.tocDropdown}>
      <select onChange={onChange} className={`paragraph paragraph--bold ${styles.tocSelect}`}>
        <option value=''>--- Content ---</option>
        {toc.map(({ text, to }) => (
          <option value={to} key={to}>{text}</option>
        ))}
      </select>
    </div>
  )
}
