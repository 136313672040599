import React from 'react'

import CaseStudyPreview from '../../components/CaseStudy'
import Section from '../../layout/Section'

import styles from '../../components/Article/scss/article-related.module.scss'

interface Props {
  otherStudies: CaseStudyPreview[]
}

export const OtherStudies: React.FC<Props> = ({ otherStudies }) => (
  <Section
    headline='See other projects'
    topPadding='large'
    bottomPadding='large'
    headlineDecoration='/images/illustrations/star-cluster2.svg'
    grid
  >
    {otherStudies.map(item => (
      <div className={`${styles.relatedArticle} cell medium-12`} key={item.slug}>
        <CaseStudyPreview {...item}/>
      </div>
    ))}
  </Section>
)
