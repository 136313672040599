import React from 'react'
import moment from 'moment'

import caseStudyStyles from './scss/case-study-info.module.scss'
import articleStyles from '../../components/Article/scss/article.module.scss'

const styles = {
  ...articleStyles,
  ...caseStudyStyles,
}

interface Props {
  caseStudy: CaseStudy
}

export const CaseStudyInfo: React.FC<Props> = ({ caseStudy }) => (
  <section>
    <h5 className={`container ${styles.lead}`}>{caseStudy.lead}</h5>
    <div className={`container ${styles.client}`}>
      {!caseStudy.hideClient &&
        <div className={styles.logo}>
          <img
            className={styles.logoImage}
            src={caseStudy.client.logo.url}
            alt={caseStudy.client.logo.alt || `${caseStudy.client.name} logo`}
          />
        </div>
      }
      <div className={styles.workPeriod}>
        <span className='h5'>Year</span>
        <p className='light-copy'>
          {projectPeriod(caseStudy.startDt, caseStudy.endDt)}
        </p>
      </div>
      <div className={styles.technologies}>
        <span className='h5'>Technologies</span>
        <p className='light-copy'>{caseStudy.technologies.join(', ')}</p>
      </div>
    </div>
  </section>
)

const projectPeriod = (startDt?: Date, endDt?: Date): string => {
  const started = (startDt && moment(startDt)) || undefined
  const ended = (endDt && moment(endDt)) || undefined
  return (
    (!started && 'Ongoing') ||
    (started && !ended && `${started.format('MMM YYYY')} - ongoing`) ||
    (started && ended && ended > moment() && `${started.format('MMM YYYY')} - ongoing`) ||
    (`${moment(startDt).format('MMM YYYY')} - ${moment(ended).format('MMM YYYY')}`)
  )
}
