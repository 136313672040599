import React from 'react'
import { graphql } from 'gatsby'

import { SmallHero } from '../../components/Hero'
import { processCaseStudy, processCaseStudyPreview, processImageSEO } from '../../contentful'
import { SeoType } from '../../enums'
import Layout from '../../layout/Layout'
import { CaseStudy } from './CaseStudy'

interface Props {
  data: {
    studies: {
      edges: Array<{
        node: CaseStudySource,
      }>,
    },
  },
  pageContext: {
    slug: string,
  },
}

const CaseStudyWrapper: React.FC<Props> = ({ data, pageContext }) => {
  const caseStudy = data.studies.edges.find(
    item => item.node.slug === pageContext.slug,
  )
  const otherStudies = data.studies.edges.filter(
    item => item.node.slug !== pageContext.slug,
  )
  // Case study will always exist, gatsby will display 404 if slug is not found during build
  return caseStudy ? (
    <Layout pageContext={createPageContext(caseStudy.node)}>
      <SmallHero img={caseStudy.node.primaryImage}/>
      <CaseStudy
        caseStudy={processCaseStudy(caseStudy.node)}
        otherStudies={otherStudies.map(item => processCaseStudyPreview(item.node))}
      />
    </Layout>
  ) : <noscript/>
}

export default CaseStudyWrapper

const createPageContext = (caseStudy: CaseStudySource): PageContext => ({
  id: caseStudy.slug,
  headline: caseStudy.headline,
  sections: [],
  subheadline: '',
  seo: {
    title: caseStudy.headline,
    description: caseStudy.lead.lead,
    type: SeoType.Article,
    image: processImageSEO(caseStudy.socialImage),
    keywords: [],
  },
})

export const query = graphql`
  query {
    studies: allContentfulCaseStudy(filter: {hasDetail: {eq: true}}) {
      edges {
        node {
          id
          slug
          headline
          hasDetail
          lead {
            lead
          }
          client {
            ... ClientFragment
          }
          hideClient
          backgroundColor
          image {
            fluid(quality: 80, maxWidth: 600) {
              ... GatsbyContentfulFluid_withWebp
            }
            ... FluidImageFragment
          }
          primaryImage {
            fluid(quality: 80, maxWidth: 1000) {
              ... GatsbyContentfulFluid_withWebp
            }
            ... FluidImageFragment
          }
          secondaryImage {
            fluid(quality: 80, maxWidth: 900) {
              ... GatsbyContentfulFluid_withWebp
            }
            ... FluidImageFragment
          }
          firstPart {
            firstPart
          }
          secondPart {
            secondPart
          }
          socialImage {
            ... SEOImageFragment
          }
          technologies
          startDt
          endDt
        }
      }
    }
  }
`
