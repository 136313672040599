import React from 'react'
import { animateScroll } from 'react-scroll'

import { ArticleContent, ArticleHeadline } from '../../components/Article'
import CallToAction from '../../components/CallToAction'
import Contact from '../../components/Contact'
import Share from '../../components/Share'
import { useToc } from '../../hooks'
import { CaseStudyInfo } from './CaseStudyInfo'
import { OtherStudies } from './OtherStudies'
import { TOC, TOCDropdown } from './TOC'

import caseStudyStyles from './scss/case-study.module.scss'
import articleStyles from '../../components/Article/scss/article.module.scss'

const styles = {
  ...articleStyles,
  ...caseStudyStyles,
}

interface Props {
  caseStudy: CaseStudy
  otherStudies: CaseStudyPreview[]
}

export const CaseStudy: React.FC<Props> = ({ caseStudy, otherStudies }) => {
  const [contactVisible, setContactVisible] = React.useState(false)

  const toc = useToc()

  return (
    <>
      <article className={`${styles.article}`}>
        <div className={styles.articleInner}>
          <ArticleHeadline headline={caseStudy.headline}/>
          <div className='container grid-x'>
            <TOC toc={toc}/>
            <div className={styles.body}>
              <CaseStudyInfo caseStudy={caseStudy}/>
              <div className={styles.tocMobileContainer}>
                <div className='container'>
                  <TOCDropdown toc={toc}/>
                </div>
              </div>
              <ArticleContent
                firstP={caseStudy.firstPart}
                secondP={caseStudy.secondPart}
                secondaryImage={caseStudy.secondaryImage}
                enumerateParagraphs
              />
              <div className={styles.shareContainer}>
                <Share title='Share this project'/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ctaContainer} >
          <CTA contactVisible={contactVisible} setContactVisible={setContactVisible}/>
        </div>
      </article>
      {contactVisible && <Contact bottomPadding='small'/>}
      {otherStudies.length && <OtherStudies otherStudies={otherStudies}/>}
    </>
  )
}

interface CTAProps {
  contactVisible: boolean
  setContactVisible: React.Dispatch<React.SetStateAction<boolean>>
}

const CTA = ({ contactVisible, setContactVisible }: CTAProps) =>
  <div className='container grid-x'>
    {!contactVisible &&
      <CallToAction
        headline="We'd love to find out more..."
        subheadline='Do you have a similar project?'
        onClick={() => {
          setContactVisible(true)
          animateScroll.scrollMore(250)
        }}
        className={`${styles.cta} cell large-9 large-offset-3`}
        withBackground
        isVertical
        addon={{
          addon: (
            <img src='/images/illustrations/planet2.svg' alt='Planet used as section decoration'/>
          ),
          className: styles.ctaAddon,
        }}
      />
    }
  </div>
